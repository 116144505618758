@import url("https://fonts.googleapis.com/css2?family=Cabin:wght@700&family=Open+Sans:wght@700&family=Yellowtail&display=swap");

/* Base styles */
body {
  font-family: "Open Sans", sans-serif;
  margin: 0;
  background-color: #f4f4f4;
}

main {
  min-height: 75vh;
  padding: 20px;
}

h1,
h2,
h3 {
  margin: 0;
}

h1 {
  font-family: "Cabin", sans-serif;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 20px;
}

h2 {
  font-size: 1.6rem;
  margin-bottom: 15px;
}

h3 {
  font-size: 1.4rem;
  margin-bottom: 10px;
}

.rating span {
  margin-right: 5px;
}

/* Carousel */
.carousel {
  border: 2px solid;
  border-image: linear-gradient(to left, rgb(112, 225, 245), #ffd194);
  border-image-slice: 1;
  margin: 20px 0;
}

.carousel img {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  display: block;
}

.productImg {
  width: 250px;
  height: 250px;
}

carouselColor {
  background-color: #f6f6f6; /* A neutral background color */
}

/* Carousel Caption */
.carousel-caption {
  background: rgba(
    0,
    0,
    0,
    0.6
  ); /* A semi-transparent background for readability */
  border-radius: 10px; /* Rounded corners */
  padding: 20px; /* Padding to space content */
}

.carousel-caption h2,
.carousel-caption h4,
.carousel-caption h5 {
  color: #ffffff; /* White text color for better contrast */
}

.carousel-caption h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.carousel-caption h4 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.carousel-caption h5 {
  font-size: 1rem;
  margin-bottom: 20px;
}

.hotpicks {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #ffd194; /* Background to pop out the text */
  padding: 5px 15px;
  font-size: 1.3rem;
  border-radius: 50px;
  color: #000; /* Black text */
}

/* Image */
Image {
  max-width: 100%;
  height: auto; /* To maintain aspect ratio */
  border-radius: 10px;
}

/* Carousel Transitions */
.carousel .carousel-item {
  transition: transform 1s ease; /* Smooth transition */
}

/* Hover effects */
.carousel-caption:hover {
  background: rgba(0, 0, 0, 0.7);
}

.hotpicks:hover {
  background-color: #70e1f5; /* Change color on hover */
}

/* Navbar */
.menu-bar {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid black;
  padding: 0 20px;
  background: linear-gradient(to right, #70e1f5, #ffd194);
}

.brand {
  font-family: "Yellowtail", cursive;
  font-size: 2rem;
  color: #fff;
  text-decoration: none;
}

.searchBox {
  flex: 1;
  padding: 10px;
  margin-left: 20px;
  border: 1px solid #110e0d;
  background: transparent;
  color: #110e0d;
}

.searchBox:focus {
  border-color: #70e1f5;
  outline: none;
  box-shadow: 0 0 5px #70e1f5;
}

.nav-link {
  margin-right: 15px;
  font-family: "Cabin", sans-serif;
  color: #fff;
  text-decoration: none;
  padding: 5px 15px;
  transition: background 0.3s ease;
}

.nav-link:hover {
  background: rgba(255, 255, 255, 0.2);
}

/* Product Card */
.productCard {
  background: #ffffff;
  border: none;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: transform 0.3s ease;
  overflow: hidden;
}

.productCard:hover {
  transform: translateY(-10px);
}

.productCard .productImg {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.productCard .card-body {
  padding: 15px 20px;
}

.productCard .card-title string {
  font-weight: 500;
  color: #333;
  text-decoration: none;
  display: block;
  margin-bottom: 10px;
}

.productCard .card-text {
  font-size: 14px;
  color: #777;
}

.productCard .card-text h3 {
  color: #333;
  margin-top: 15px;
}

/* Utility Class */
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Media Queries */
@media only screen and (max-width: 768px) {
  .carousel {
    display: none;
  }

  .productCard {
    margin-bottom: 20px;
  }

  #responsive-navbar-nav {
    display: none; /* Reconsider this for mobile navigation */
  }
}
